import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                Happy Home Kindergarten is a pre-school, established by Happy Home Education Foundation Pvt Ltd. It is situated in a two and a half ropanis of land at Dhapakhel - 23, Lalitpur, creating a calming environment for young learners.
                </Text>
                <Text variant='p'>
                We have three compounds – math compound, science and physical education compound, and social compound. Each compound is physically set up in order to have the children learn the concept of those academic subjects and pre-school skills in a fun and constructive way, without going through any textbooks or formal instructions or rigorous assessments.
                </Text>
                <Text variant='p'>
                We have currently three primary classes – Playgroup, Nursery, JKG and SKG - at Happy Home Kindergarten that cater for children aged 18 months to 6 years. Each class has a qualified teacher and teaching assistants to support the setting. All our teachers are affectionate and develop a strong bond with children
                </Text>
                <Text variant='p'>
                Our class usually includes no more than 15 children and maintains the teacher child ration to 1:10 (a group of ten children are supervised by one teacher). This small class size creates a friendly, welcoming environment where children have the freedom to choose their work and discover their own individual interests, supported by a team of qualified teachers.
                </Text>
                <Text variant='p'>
                We believe that child's early year education is a preparation for life. This should be a holistic approach that integrates mind, body and spirit to achieve a truly social school community in which personal relationships and skills are given the highest priority
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Reading Club'>
                <Card variant='paper'>
                Happy Home Reading Club is an after school club that aims to provide students with the skills to read fluently, accurately and with understanding. This club is meant for the students of pre-primers to Grade five. We have reading test papers to check students’ reading fluency, literacy books, make-believe word list, and frequency word lists to help students with reading. We have a reading library with classic and modern fiction, nonfiction, school textbooks and other reference materials.
                </Card>
              </Section>
              <Divider />
              <Section title='Writing Club'>
                <Card variant='paper'>
                  This club is also an afterschool club and is meant for the students of Grade five to ten with writing skills. Teachers of this club help students with structured writing (paragraph, essay, story, report, etc), grammar, and vocabulary skills. It is also open on Saturday and other long holidays. Interested parents can read our reading and writing club brochure for detailed information.
                </Card>
              </Section>
              <Divider />
              <Section title='Happy Home Teacher Training'>
                <Card variant='paper'>
                Happy Home Teacher Training Unit provides authentic trainings on two main areas: pre-primary teacher training for pre-school teachers and methods of teaching English to school students for English teachers.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
